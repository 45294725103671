import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { interpolateBlues } from 'd3-scale-chromatic';
import './Treemap.css'; // Import the CSS file

const Treemap = ({ data }) => {
  const svgRef = useRef();
  const [tooltipData, setTooltipData] = useState({
    visible: false,
    cash: 0,
    symbol: '',
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const width = svgRef.current.clientWidth;
    const height = width * 0.5; // Maintain aspect ratio

    // Clear the previous SVG content
    d3.select(svgRef.current).selectAll('*').remove();

    // Create the root for the treemap
    const root = d3
      .hierarchy({
        children: data.map((d) => ({ name: d.symbol, value: d.cash })),
      })
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);

    // Create a treemap layout
    d3
      .treemap()
      .size([width, height])
      .padding(1)(root);

    // Determine if device is mobile
    const isMobile = window.innerWidth <= 600;

    // Create a color scale based on cash values
    const cashValues = data.map((d) => d.cash);
    const minCash = Math.min(...cashValues);
    const maxCash = Math.max(...cashValues);

    const colorScale = d3.scaleSequential()
      .domain([minCash, maxCash])
      .interpolator(interpolateBlues);

    // Create the SVG
    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    // Append rectangles for each node
    svg
      .selectAll('rect')
      .data(root.leaves())
      .enter()
      .append('rect')
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', (d) => colorScale(d.data.value)) // Apply color scale
      .attr('stroke', 'white')
      .style('cursor', isMobile ? 'default' : 'crosshair')
      .on('mouseover', (event, d) => {
        if (!isMobile) {
          setTooltipData({
            visible: true,
            cash: d.data.value,
            symbol: d.data.name,
            x: event.pageX,
            y: event.pageY,
          });
        }
      })
      .on('mouseout', () => {
        setTooltipData((prev) => ({ ...prev, visible: false }));
      });

    // Append text labels
    svg
      .selectAll('text')
      .data(root.leaves())
      .enter()
      .append('text')
      .attr('x', (d) => d.x0 + 5)
      .attr('y', (d) => d.y0 + 20)
      .text((d) => d.data.name)
      .attr('font-size', '12px')
      // Dynamically set text color based on background lightness
      .attr('fill', (d) => {
        const bgColor = colorScale(d.data.value);
        const hslColor = d3.hsl(bgColor);
        const lightness = hslColor.l;
        return lightness > 0.6 ? 'black' : 'white';
      })
      .style('pointer-events', 'none'); // So the text doesn't capture mouse events

  }, [data]);

  return (
    <>
      <svg ref={svgRef} className="treemap"></svg>
      {tooltipData.visible && (
        <div
          className="tooltip"
          style={{
            left: tooltipData.x + 10,
            top: tooltipData.y - 30,
            position: 'absolute',
            pointerEvents: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '5px',
            borderRadius: '4px',
            zIndex: 1000,
          }}
        >
          <div>Symbol: {tooltipData.symbol}</div>
          <div>Current Assets: ${tooltipData.cash.toLocaleString()}</div>
        </div>
      )}
    </>
  );
};

export default Treemap;

import { useQuery } from "@apollo/client";
import ContainerTitle from "../../../components/ContainerTitle";
import { GET_TRADE_EVENTS } from "../../../utils/queries";
import "./LastTrade.css";

const LastTrade = () => {
    const { loading, error, data } = useQuery(GET_TRADE_EVENTS);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error</p>;

    return (
        <>
            <ContainerTitle title={"Yesterday's Notable Trades"} />
            <div className="trade-events-container">
                {data.getTradeEvents.map((event, index) => {
                    let tradeAction = "";
                    let tradeColor = "";

                    if (event.optionType === "call") {
                        if (event.priceSpike > 0) {
                            tradeAction = "Calls Bought";
                            tradeColor = "trade-event-green"; // Light green for calls bought
                        } else {
                            tradeAction = "Calls Sold";
                            tradeColor = "trade-event-red"; // Light red for calls sold
                        }
                    } else if (event.optionType === "put") {
                        if (event.priceSpike > 0) {
                            tradeAction = "Puts Sold";
                            tradeColor = "trade-event-green"; // Light green for puts sold
                        } else {
                            tradeAction = "Puts Bought";
                            tradeColor = "trade-event-red"; // Light red for puts bought
                        }
                    }

                    return (
                        <div key={index} className={`trade-event ${tradeColor}`}>
                            <p><span className="label">Symbol:</span> <span className="value">{event.symbol}</span></p>
                            <p><span className="label">Strike:</span> <span className="value">{event.strike}</span></p>
                            <p><span className="label">Size:</span> <span className="value">{event.size}</span></p>
                            <p><span className="label">Price:</span> <span className="value">{event.price}</span></p>
                            <p><span className="label">Type:</span> <span className="value">{event.optionType}</span></p>
                            <p><span className="label">Expiration:</span> <span className="value">{event.expiration}</span></p>
                            <p><span className="label">Dollar Amount:</span> <span className="value">${event.dollarAmount}</span></p>
                            <p><span className="label">Stock Price Change:</span> <span className="value">${event.priceSpike}</span></p>
                            <p><span className="label">Trade Action:</span> <span className="value">{tradeAction}</span></p>
                            <p><span className="label">Timestamp:</span>
                                <span className="value">
                                    {new Intl.DateTimeFormat("en-US", {
                                        timeZone: "America/Los_Angeles",
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit"
                                    }).format(new Date(event.timestamp))}
                                </span>
                            </p>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default LastTrade;

import './option.css';

const OptionContract = ({ data }) => {
    return (<div className="optioncontract">
        <div><div>Symbol</div> <div>{data.symbol}</div></div>
        <div><div>Strike</div> <div>{data.strike}</div></div>
        <div><div>Type</div> <div>{data.optionType}</div></div>
        <div><div>Expiration</div> <div>{data.expiration}</div></div>
        <div><div>Volume</div> <div>{data.lastFiveDays[0].v}</div></div>
        <div><div>Yesterday Volume</div> <div>{data.lastFiveDays[1].v}</div></div>
        <div><div>Average Volume</div> <div>{data.volumeAverage}</div></div>
        <div><div>Open Interest</div> <div>{data.openInterest}</div></div>
        <div><div>Yesterday Open Interest</div> <div>{data.lastFiveDays[1].n}</div></div>
        <div><div>Open Interest Average</div> <div>{data.openInterestAverage}</div></div>
        <div><div>Bid Price</div> <div>{data.bidPrice.toFixed(2)}</div></div>
        <div><div>Mid Price</div> <div>{data.midPrice.toFixed(2)}</div></div>
        <div><div>Ask Price</div> <div>{data.askPrice.toFixed(2)}</div></div>
        {/* <div><div>Last Price</div> <div>{data.mostRecentDay.close ?? data.lastFiveDays[0].c}</div></div> */}
        <div><div>Vol/OI</div> <div>{(data.lastFiveDays[0].v / data.openInterest).toFixed(2)}</div></div>
    </div>);
}

export default OptionContract;
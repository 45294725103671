// src/components/OptionAggregateChart.js

import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OptionAggregateChart = ({ expiration, call, put }) => {
  // Prepare data for the chart
  const data = {
    labels: [
      'Call Open Interest',
      'Put Open Interest',
      'Call Yesterday\'s Open Interest',
      'Put Yesterday\'s Open Interest',
    ],
    datasets: [
      {
        label: 'Open Interest',
        data: [
          call ? call.totalOpenInterest : 0,
          put ? put.totalOpenInterest : 0,
          call ? call.totalYesterdayOpenInterest : 0,
          put ? put.totalYesterdayOpenInterest : 0,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)', // Call Open Interest
          'rgba(255, 99, 132, 0.6)', // Put Open Interest
          'rgba(75, 192, 192, 0.4)', // Call Yesterday's Open Interest
          'rgba(255, 99, 132, 0.4)', // Put Yesterday's Open Interest
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend since labels are self-explanatory
      },
      title: {
        display: true,
        text: `Aggregates for Expiration: ${expiration}`,
        font: {
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y}`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Open Interest',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Option Type',
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

OptionAggregateChart.propTypes = {
  expiration: PropTypes.string.isRequired,
  call: PropTypes.shape({
    totalOpenInterest: PropTypes.number.isRequired,
    totalYesterdayOpenInterest: PropTypes.number.isRequired,
  }),
  put: PropTypes.shape({
    totalOpenInterest: PropTypes.number.isRequired,
    totalYesterdayOpenInterest: PropTypes.number.isRequired,
  }),
};

OptionAggregateChart.defaultProps = {
  call: null,
  put: null,
};

export default OptionAggregateChart;

import { useQuery } from '@apollo/client';
import { GET_OPTIONCHAIN } from '../../utils/queries';
import React from 'react';
import OptionContract from './OptionChain/OptionContract';

const DisplayOption = ({ symbol }) => {
    const { loading, data } = useQuery(GET_OPTIONCHAIN, { variables: { symbol } });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!data || !data.getOptionChain) {
        return <div>No data available.</div>;
    }

    // Make a shallow copy of the options before sorting to avoid mutation issues
    const sortedOptions = [...data.getOptionChain].sort((a, b) => {
        const aChange = Number(a.openInterestChange);
        const bChange = Number(b.openInterestChange);
        return bChange - aChange;
    });

    return (
        <>
            <h3 style={{ textAlign: "center", margin: "0 auto", border: "1px solid black", maxWidth: "800px", backgroundColor: "lightgray" }}>
                Option Chain
            </h3>
            <div className="optionchain-container">
                {sortedOptions.map((option, index) => (
                    <OptionContract key={index} data={option} />
                ))}
            </div>
        </>
    );
};


export default DisplayOption;

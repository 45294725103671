// src/components/DisplayOptionAggregates.js

import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_OPTION_AGGREGATES } from '../../utils/queries';
import PropTypes from 'prop-types';
import OptionAggregateChart from './OptionAggregateChart';

const DisplayOptionAggregates = ({ symbol }) => {
  // Execute the GraphQL query with the provided symbol
  const { loading, error, data } = useQuery(GET_OPTION_AGGREGATES, {
    variables: { symbol },
    fetchPolicy: 'cache-and-network', // Adjust based on your caching strategy
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Check if getOptionAggregates exists
  const { getOptionAggregates } = data;

  if (!getOptionAggregates) {
    return <p></p>;
  }

  const { aggregates, symbol: fetchedSymbol } = getOptionAggregates;

  // Transform the data to group by expiration
  const groupedData = aggregates.reduce((acc, curr) => {
    const { expiration, optionType, totalOpenInterest, totalYesterdayOpenInterest } = curr;

    // Find if the expiration already exists in the accumulator
    const existing = acc.find(item => item.expiration === expiration);

    if (existing) {
      // Assign to call or put based on optionType
      if (optionType.toLowerCase() === 'call') {
        existing.call = { totalOpenInterest, totalYesterdayOpenInterest };
      } else if (optionType.toLowerCase() === 'put') {
        existing.put = { totalOpenInterest, totalYesterdayOpenInterest };
      }
    } else {
      // Create a new group
      const newGroup = { expiration };
      if (optionType.toLowerCase() === 'call') {
        newGroup.call = { totalOpenInterest, totalYesterdayOpenInterest };
      } else if (optionType.toLowerCase() === 'put') {
        newGroup.put = { totalOpenInterest, totalYesterdayOpenInterest };
      }
      acc.push(newGroup);
    }

    return acc;
  }, []);

  // Optional: Sort the groupedData by expiration date ascending
  groupedData.sort((a, b) => new Date(a.expiration) - new Date(b.expiration));

  return (
    <div>
      <h2>Option Aggregates for Symbol: {fetchedSymbol}</h2>
      {groupedData.length === 0 ? (
        <p>No aggregates available to display.</p>
      ) : (
        <div style={styles.chartContainer}>
          {groupedData.map((group, index) => (
            <OptionAggregateChart
              key={index}
              expiration={group.expiration}
              call={group.call}
              put={group.put}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Define PropTypes for type checking
DisplayOptionAggregates.propTypes = {
  symbol: PropTypes.string.isRequired,
};

// Define styles
const styles = {
  chartContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
  },
};

export default DisplayOptionAggregates;

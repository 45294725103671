import { useQuery } from '@apollo/client';
import { GET_CASHMAP } from '../../../utils/queries';

import Treemap from "../../../components/Treemap";
import ContainerTitle from "../../../components/ContainerTitle";

const CashMap = () => {
    const { loading, data } = useQuery(GET_CASHMAP);

    if (loading) {
        return <p>Loading...</p>;
    }
    
    return (
        <>
            <ContainerTitle title={"Current Assets Map"} />
            <Treemap data={data.getCashMap.slice(0,50)} />
        </>
    );
}

export default CashMap;